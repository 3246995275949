<template>
  <PageTitleTop>
    <template v-slot:title>账户余额</template>
    <HeaderAreaVue></HeaderAreaVue>
    <PageSection>
      <template v-slot:title>余额使用明细</template>
      <Row>
        <Col span="6">
          <DatePicker
            :model-value="searchData.time"
            format="yyyy-MM-dd"
            type="daterange"
            placement="bottom-end"
            placeholder="选择时间段"
            style="width: 200px"
            @on-change="dateChange"
          ></DatePicker>
        </Col>
        <Col span="4">
          <Select
            v-model="searchData.otherTime"
            style="width: 200px"
            clearable
            @on-change="timeRangeChange"
          >
            <Option :value="1">最近一周</Option>
            <Option :value="2">最近一个月</Option>
            <Option :value="3">最近三个月</Option>
            <Option :value="4">最近半年</Option>
            <Option :value="5">最近一年</Option>
          </Select>
        </Col>
      </Row>
      <Table
        :columns="columns"
        :data="dataList"
        :fixed-shadow="'auto'"
        class="table-box"
      >
        <template #orderDetail="{ row }">
          <div class="order-detail-td" @click="toBankBackOrder(row)">
            <div>
              {{
                row.description + '【' + row.amount + '】'
              }}
            </div>
            <div class="secondary-info">
              {{ row.transDateTime }}
            </div>
          </div>
        </template>
        <template #price="{ row }">
          <div class="amount">
            {{ row.flag === 0 ? `-${row.amount}` : `+${row.amount}` }}
          </div>
        </template>
        <template #action="{ row, index }">
          <Row justify="start">
            <Button
              type="text"
              size="small"
              style="color: #80beff; background: none; box-shadow: none"
              @click="toDetail({ row, index })"
            >
              查看详情
            </Button>
          </Row>
        </template>
      </Table>
      <Row justify="end" class="table-page">
        <Page
          v-model="pageInfo.pageIndex"
          :total="pageInfo.total"
          :page-size="pageInfo.pageSize"
          @on-change="pageChange"
        />
      </Row>
    </PageSection>
  </PageTitleTop>
</template>

<script>
import { initAccountBalanceListHeader } from '../config'
import HeaderAreaVue from '../components/HeaderArea'
import moment from 'moment'
import VueEvent from '@/model/event'
export default {
  name: 'AccountBalance',
  data () {
    return {
      searchData: {
        time: [],
        otherTime: '',
      },
      pageInfo: {
        pageIndex: 1,
        total: 0,
        pageSize: 10,
      },
      dataList: [],
    }
  },
  setup () {
    const columns = initAccountBalanceListHeader()
    return {
      columns,
    }
  },
  created () {
    this.type = this.$store.state.member.memberCenter.status
    if (typeof this.type === 'undefined') {
      this.getPcMemberMemberCenter()
    }
    this.isType()
    this.getDetail()
    this.getPcMemberMemberCenter()
  },
  methods: {
    timeRangeChange (val) {
      if (!val) {
        return
      }
      this.searchData.time = []
      this.searchData.time[1] = moment().format('YYYY-MM-DD')
      switch (val) {
        case 1:
          this.searchData.time[0] = moment()
            .subtract(7, 'days')
            .format('YYYY-MM-DD')
          break
        case 2:
          this.searchData.time[0] = moment()
            .subtract(1, 'month')
            .format('YYYY-MM-DD')
          break
        case 3:
          this.searchData.time[0] = moment()
            .subtract(3, 'month')
            .format('YYYY-MM-DD')
          break
        case 4:
          this.searchData.time[0] = moment()
            .subtract(6, 'month')
            .format('YYYY-MM-DD')
          break
        case 5:
          this.searchData.time[0] = moment()
            .subtract(1, 'year')
            .format('YYYY-MM-DD')
          break
      }
      this.getDetail()
    },
    getDetail () {
      const time = this.searchData.time.length ? this.searchData.time : ['', '']
      const params = {
        page: this.pageInfo.pageIndex,
        rows: this.pageInfo.pageSize,
        startDate: time[0].replace(/-/g, ''),
        endDate: time[1].replace(/-/g, ''),
        serialNo: '',
        orderNo: '',
        transType: 14, // 余额type
      }
			this.showSpin()
      this.$store
        .dispatch('accountMoney/GetDetail', params)
        .then((res) => {
          if (res.returnCode === '1') {
            this.dataList = res.result.rows
            this.pageInfo.total = res.result.records
            this.$Spin.hide()
          } else {
            this.$Spin.hide()
          }
        })
        .catch(() => {})
    },
    toDetail () {},
		toBankBackOrder (data) {
			this.showSpin()
      // console.log(data)
      const params = {
        serialNo: data.serialNo,
        transDate: data.transDate,
      }
      this.$store
        .dispatch('accountMoney/BankBackOrder', params)
        .then((res) => {
					this.$Spin.hide()
          if (res.returnCode === '1') {
						window.open(res.result.authUrl, '_self')
          }
        })
        .catch(() => {})
    },
    pageChange () {
      this.getDetail()
    },
    dateChange (val) {
      this.searchData.otherTime = ''
      this.searchData.time = val
      this.getDetail()
    },
    isType () {
      if (this.type === 7) {
        // 已开户
        this.$router.push('/user/account/balance').then(() => {
          this.showPage = true
        })
      } else if (this.type === 4 || this.type === 5 || this.type === 6) {
        // 待开户 | 开户失败
        this.$router.push('/user/account/balance/open-account').then(() => {
          this.showPage = true
        })
      }
    },
    getPcMemberMemberCenter () {
      // 个人中心 会员中心数据
      this.$store
        .dispatch('PcMemberMemberCenter', {})
        .then((res) => {
          if (res.returnCode === '1') {
            VueEvent.emit('canWithDrawMitt', res.result.canWithDraw)
          }
        })
        .catch(() => {})
    },
		showSpin () {
      this.$Spin.show({
        render: (h) => {
          return h('div', [
            h(
              'div',
              {
                style: {
                  color: '#d12b23',
                  fontSize: '22px',
                },
              },

              '正在加载银行数据。请等待...',
            ),
          ])
        },
      })
    },
  },
  components: {
    HeaderAreaVue,
  },
}
</script>

<style lang="less" scoped>
.code-row-bg {
  padding: 20px 0;
}
.order-detail-td {
}
.table-box {
  margin-top: 20px;
}
.amount {
  font-size: 18px;
}
</style>
