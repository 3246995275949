<template>
  <div class="header-area">
    <div v-if="!isFrozen">
      <div class="secondary-info">人民币（元）</div>
      <div class="money">
        <!-- <CountUp v-font="32" class="money" :end="info.balance || 0" :duration="1" /> -->
        <label class="money-text">{{ info.balance }}</label>
        <Button class="commit-btn" size="large" @click="showDialog = true">
          充值
        </Button>
        <Button v-if="isCanWithDraw ===1" class="default-btn" size="large" @click="toWithdraw">
          提现
        </Button>
				 <Button v-else class="default-btn"  disabled size="large" >
          不可提现
        </Button>
      </div>
      <div>
        <span class="secondary-info">已冻结资金（元）</span>
        <div class="sub-money">
          <label>{{ info.freezeBalance }}</label>
          <!-- <CountUp
            v-font="24"
            class="sub-money"
            :end="info.freezeBalance || 0"
            :duration="1"
          /> -->
        </div>
      </div>
    </div>
    <div v-else>
      <div class="secondary-info">已冻结资金（元）</div>
      <div>
        <CountUp
          v-font="32"
          class="money"
          :decimals="2"
          :end="info.freezeBalance"
          :duration="1"
        />
      </div>
    </div>
  </div>
  <Modal
    v-model="showDialog"
    title="提示"
    :footer-hide="true"
    :width="300"
    :mask="true"
  >
    <div>请去线下充值</div>
    <div class="footer">
      <Button class="commit-btn" @click="showDialog = false">确定</Button>
    </div>
  </Modal>
</template>

<script>
import VueEvent from '@/model/event'
export default {
  name: 'AccountBalance',
  props: {
    isFrozen: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      info: {
        balance: 0,
        freezeBalance: 0,
      },
      widthDrawUrl: '',
      showDialog: false,
      isCanWithDraw: 1, // 是否可提现 0：不可；1可以
    }
  },
  created () {
    this.getBalance()
    this.getWithdrawUrl()
  },
  mounted () {
    this.canWithDrawMitt()
  },
  methods: {
    getWithdrawUrl () {
      this.$Spin.show({
        render: (h) => {
          return h('div', [
            h(
              'div',
              {
                style: {
                  color: '#d12b23',
                  fontSize: '22px',
                },
              },

              '正在加载银行数据。请等待...',
            ),
          ])
        },
      })

      this.$store
        .dispatch('accountMoney/GetWithdrawUrl', {})
        .then((res) => {
          this.$Spin.hide()
          if (res.returnCode === '1') {
            this.widthDrawUrl = res.result.authUrl
          }
        })
        .catch(() => {})
    },
    toWithdraw () {

      if (this.isCanWithDraw === 1) {
        window.open(this.widthDrawUrl, '_self')
      }

    },
    canWithDrawMitt () {
      VueEvent.on('canWithDrawMitt', (value) => {
        this.isCanWithDraw = value
        console.log(value)
      })
    },
    getBalance () {
      this.$store
        .dispatch('accountMoney/GetBanlance', {})
        .then((res) => {
          if (res.returnCode === '1') {
            this.info = res.result
          }
        })
        .catch(() => {})
    },
  },
  components: {},
}
</script>

<style lang="less" scoped>
.money {
  display: flex;
  align-items: center;
  margin-right: 80px;
  color: @xtxColor;
  button {
    margin-right: 30px;
  }
  .money-text {
    font-size: 40px;
    margin-right: 40px;
  }
}
.secondary-info {
  font-size: 14px;
  line-height: 30px;
}
.header-area {
  border-bottom: 2px solid #f4f5f7;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.sub-money {
  font-size: 24px;
}
.footer {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  .commit-btn {
    width: 60px;
    padding: 0;
  }
}
</style>
